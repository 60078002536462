.unsubConyainer {
  width: 100vw;
  height: 100vh;

  margin: auto;

  display: flex;
  flex-flow: column;

  background-color: #FFF;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../images/login/bgImg.png');

  z-index: 1;
}

/* ========================================================= */

.unsubConyainer .container {
  width: 100vw;
  height: 100dvh;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  margin: 0 auto;


  scrollbar-width: thin;
  scrollbar-color: var(--color-main) transparent;

  overflow-x: hidden;
  overflow-y: auto;

  z-index: 200;
}

.unsubConyainer .container .title {
  width: 55%;
  height: 95%;

  margin: auto;

  display: flex;
  flex-flow: column;
}

.unsubConyainer .container .title .logoFDN {
  width: 20vw;
  height: calc(20vw / 3.1528);

  margin: 5% auto 0% 5%;

  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url('../../images/login/fdnLogo.png');
}

.unsubConyainer .container .title .infoTexts {
  width: 90%;

  margin: 5% 0% auto auto;

  display: flex;
  flex-flow: column;
}

.unsubConyainer .container .title .infoTexts h1 {
  width: 100%;

  margin: 20px 0 auto auto;

  color: var(--color-main);
  font-size: 3rem;
  line-height: 3rem;
}

.unsubConyainer .container .title .infoTexts h1 span {
  background-color: var(--color-main);
  color: #FFF;
}

/* ====================================================== */

.unsubConyainer .container .title .infoTexts .subtitle {
  position: relative;

  width: 100%;
  margin: 20px auto;
  display: flex;
}

.unsubConyainer .container .title .infoTexts .subtitle .lines {
  width: 5px;
  min-width: 5px;
  height: 100%;
  margin: auto 10px;
  display: flex;
  flex-flow: column;
}

.unsubConyainer .container .title .infoTexts .subtitle .lines div {
  width: 100%;
  height: 32%;

  margin: auto;

  background-color: #CCC;
}

.unsubConyainer .container .title .infoTexts .subtitle .lines .line_1 {
  background-color: #babcbf;
}

.unsubConyainer .container .title .infoTexts .subtitle .lines .line_2 {
  background-color: #6d6e71;
}

.unsubConyainer .container .title .infoTexts .subtitle .lines .line_3 {
  background-color: #7a1425;
}

.unsubConyainer .container .title .infoTexts .subtitle h2 {
  width: 90%;
  margin: auto;

  color: #111;
  font-size: 2.5rem;
  line-height: 3rem;
  font-family: Grafik_Regular_Italic;
}

.unsubConyainer .container .title .infoTexts .subtitle h2 span {
  font-family: Grafik_Bold_Italic;
}

/* +++++++++++++++++++++++++++++++++++ */

.unsubConyainer .container .title .infoTexts .dateInfo {
  width: 100%;

  margin: auto;

  display: flex;
}

.unsubConyainer .container .title .infoTexts .dateInfo div {
  width: 30%;

  margin: 0 auto auto auto;

  display: flex;
  flex-flow: column;
}

/* ---------------------- */

.unsubConyainer .container .title .infoTexts .dateInfo .date h4 {
  width: 100%;

  margin: 5px auto;

  text-align: end;
  color: var(--color-main);
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.unsubConyainer .container .title .infoTexts .dateInfo .date p {
  width: 100%;

  margin: auto;

  text-align: end;
  color: #111;
  font-size: 1.5rem;
}

/* ------------------------- */

.unsubConyainer .container .title .infoTexts .dateInfo .place {
  border: none;
  border-left: 1px solid #666;
  border-right: 1px solid #666;
}

.unsubConyainer .container .title .infoTexts .dateInfo .place h4 {
  width: 90%;

  margin: 6px auto;

  text-align: start;
  color: #222;
  font-family: Grafik_Light;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.unsubConyainer .container .title .infoTexts .dateInfo .place p {
  width: 90%;

  margin: auto;

  background-color: #666;

  text-align: start;
  color: #FFF;
  font-family: Grafik_Light;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

/* ------------------------ */

.unsubConyainer .container .title .infoTexts .dateInfo .contact {
  width: 35%;
}

.unsubConyainer .container .title .infoTexts .dateInfo .contact h4 {
  width: 95%;

  margin: auto auto 5px auto;

  text-align: start;
  color: #222;
  font-family: Grafik_Medium;
  font-size: 1.3rem;
  line-height: 1.3rem;
}

.unsubConyainer .container .title .infoTexts .dateInfo .contact div {
  width: 95%;
  margin: 2px auto;
  display: flex;
  flex-flow: row;
}

.unsubConyainer .container .title .infoTexts .dateInfo .contact div p {
  width: 100%;
  margin: auto;
  font-size: 16px;
  font-family: Grafik_Light;
}

.unsubConyainer .container .title .infoTexts .dateInfo .contact .wtsCont .icon {
  width: 25px;
  height: 25px;
  min-width: 25px;

  margin: auto 3px auto auto;

  border-radius: 5px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url('../../images/login/wts.png');
}

.unsubConyainer .container .title .infoTexts .dateInfo .contact .emailCont .icon {
  width: 25px;
  height: 25px;
  min-width: 25px;

  margin: auto 3px auto auto;

  border-radius: 5px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url('../../images/login/email.png');
}


/* ------------------------------------------------------- */

.unsubConyainer .container .formCont {
  width: 45%;
  min-width: 450px;
  height: 100dvh;

  margin: auto;

  display: flex;
  flex-flow: column;
}

.unsubConyainer .container .formCont .messageCont {
  width: 80%;

  margin: auto;
  padding: 5%;

  display: flex;
  flex-flow: column;

  background-color: #FFF;
  border-radius: 20px;
  box-shadow: -10px 10px 10px #1113;
}

.unsubConyainer .container .formCont .messageCont h2 {
  width: 100%;

  margin: auto;

  color: var(--color-main);
  font-size: 2rem;
  line-height: 2rem;
  text-align: center;
}

.unsubConyainer .container .formCont .messageCont p {
  width: 100%;

  margin: 20px auto;

  color: #333;
  font-size: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
}

/* ========================================================= */

.unsubConyainer footer {
  position: absolute;
  bottom: 5px;
  left: 0;

  width: 100vw;

  margin: auto;

  display: flex;

  z-index: 200;
}

.unsubConyainer footer a {
  margin: auto;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: var(--color-main);
  color: #FFF;
  text-decoration: none;
}

.unsubConyainer .logoVigilado {
  position: fixed;
  bottom: 3%;
  left: 1%;

  width: calc(13vw * 0.096);
  height: 13vw;

  margin: auto;

  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url('../../images/login/vigilado.png');
}

/* =================================================== */
/* =================================================== */
/* =================================================== */
/* =================================================== */
/* =================================================== */
/* =================================================== */
/* =================================================== */
/* =================================================== */
/* =================================================== */
/* =================================================== */
/* =================================================== */

@media screen and (max-width: 1366px) {
  .unsubConyainer .container .title .logoFDN {
    width: 20vw;
    height: calc(20vw / 3.1528);
    margin: 5% auto 0% 5%;
  }

  .unsubConyainer .container .title .infoTexts h1 {
    margin: 20px 0 auto auto;
    font-size: 3vw;
    line-height: 3vw;
  }

  .unsubConyainer .container .title .infoTexts .subtitle h2 {
    font-size: 2.3vw;
    line-height: 2.8vw;
  }

  /* ============================ */

  .unsubConyainer .container .title .infoTexts .dateInfo .date h4 {
    font-size: 1.3vw;
    line-height: 1.3vw;
  }

  .unsubConyainer .container .title .infoTexts .dateInfo .date p {
    font-size: 1.3vw;
    line-height: 1.3vw;
  }

  /* --------------------- */

  .unsubConyainer .container .title .infoTexts .dateInfo .place h4 {
    margin: 6px auto;
    font-size: 1.3vw;
    line-height: 1.3vw;
  }

  .unsubConyainer .container .title .infoTexts .dateInfo .place p {
    font-size: 1.3vw;
    line-height: 1.3vw;
  }

  /* ------------------------ */

  .unsubConyainer .container .title .infoTexts .dateInfo .contact h4 {
    font-size: 1.3vw;
    line-height: 1.3vw;
  }

  .unsubConyainer .container .title .infoTexts .dateInfo .contact div p {
    font-size: 1.2vw;
  }

  /* ================== */

  .unsubConyainer footer a {
    padding: 10px 18px;
    font-size: 1.2vw;
  }

  .unsubConyainer .logoVigilado {
    width: calc(15vw * 0.096);
    height: 15vw;
  }
}


@media screen and (max-width: 1024px) {
  .unsubConyainer {
    background-position: top right;
    background-repeat: no-repeat;
    background-size: 180%;;
  }

  /* ========================================================= */

  .unsubConyainer .container {
    margin: 0px auto 50px auto;
  }

  .unsubConyainer .container .title {
    position: relative;

    width: 100%;
    height: auto;
    margin: 0px auto 0 auto;
  }

  .unsubConyainer .container .title .logoFDN {
    width: 30vw;
    height: calc(30vw / 3.1528);
    margin: 5% auto 0% 5%;
  }

  .unsubConyainer .container .title .infoTexts {
    margin: 2% 0% auto auto;
  }

  .unsubConyainer .container .title .infoTexts h1 {
    margin: 20px 0 auto auto;
    font-size: 3.5vw;
    line-height: 3.5vw;
  }

  .unsubConyainer .container .title .infoTexts .subtitle .lines {
    height: 13vw;
  }

  .unsubConyainer .container .title .infoTexts .subtitle h2 {
    font-size: 3.5vw;
    line-height: 4vw;
    width: 85%;
    margin: auto auto auto 2%;
  }

  .unsubConyainer .container .title .infoTexts .dateInfo {
    margin: 5% auto;
  }

  .unsubConyainer .container .title .infoTexts .dateInfo div {
    width: 23%;
  }

  /* ============================ */

  .unsubConyainer .container .title .infoTexts .dateInfo .date h4 {
    font-size: 2.3vw;
    line-height: 2.3vw;
  }

  .unsubConyainer .container .title .infoTexts .dateInfo .date p {
    font-size: 2vw;
    line-height: 2vw;
  }

  /* --------------------- */

  .unsubConyainer .container .title .infoTexts .dateInfo .place {
    width: 30%;
  }

  .unsubConyainer .container .title .infoTexts .dateInfo .place h4 {
    margin: 5px auto;
    font-size: 2.3vw;
    line-height: 2.3vw;
  }

  .unsubConyainer .container .title .infoTexts .dateInfo .place p {
    font-size: 2.3vw;
    line-height: 2.3vw;
  }

  /* ------------------------ */

  .unsubConyainer .container .title .infoTexts .dateInfo .contact {
    width: 45%;
  }

  .unsubConyainer .container .title .infoTexts .dateInfo .contact h4 {
    font-size: 2vw;
    line-height: 2vw;
  }

  .unsubConyainer .container .title .infoTexts .dateInfo .contact div p {
    font-size: 1.7vw;
  }

  /* ------------------------------ */

  .unsubConyainer .container .formCont {
    width: 100%;
    min-width: 350px;
    height: auto;

    margin: 30px auto;
  }

  /* ----------------------------- */

  .unsubConyainer footer a {
    padding: 10px 18px;
    font-size: 14px;
  }

  .unsubConyainer .logoVigilado {
    width: calc(25vw * 0.096);
    height: 25vw;
  }

}

@media screen and (max-width: 768px) {

  .unsubConyainer .container {
    margin: 0px auto 50px auto;
  }

  .unsubConyainer .container .title .logoFDN {
    width: 40vw;
    height: calc(40vw / 3.1528);
    margin: 5% auto 0% 5%;
  }

  .unsubConyainer .container .title .infoTexts h1 {
    margin: 20px 0 auto auto;
    font-size: 4.5vw;
    line-height: 4.5vw;
  }

  .unsubConyainer .container .title .infoTexts .subtitle .lines {
    height: 18vw;
  }

  .unsubConyainer .container .title .infoTexts .subtitle h2 {
    font-size: 4vw;
    line-height: 4.5vw;
    width: 85%;
    margin: auto auto auto 2%;
  }

  .unsubConyainer .container .title .infoTexts .dateInfo {
    margin: 5% auto;
  }

  .unsubConyainer .container .title .infoTexts .dateInfo div {
    width: 23%;
  }

  /* ============================ */

  .unsubConyainer .container .title .infoTexts .dateInfo .date h4 {
    font-size: 2.3vw;
    line-height: 2.3vw;
  }

  .unsubConyainer .container .title .infoTexts .dateInfo .date p {
    font-size: 2vw;
    line-height: 2vw;
  }

  /* --------------------- */

  .unsubConyainer .container .title .infoTexts .dateInfo .place {
    width: 30%;
  }

  .unsubConyainer .container .title .infoTexts .dateInfo .place h4 {
    margin: 5px auto;
    font-size: 2.3vw;
    line-height: 2.3vw;
  }

  .unsubConyainer .container .title .infoTexts .dateInfo .place p {
    font-size: 2.3vw;
    line-height: 2.3vw;
  }

  /* ------------------------ */

  .unsubConyainer .container .title .infoTexts .dateInfo .contact {
    width: 45%;
  }

  .unsubConyainer .container .title .infoTexts .dateInfo .contact h4 {
    font-size: 2vw;
    line-height: 2vw;
  }

  .unsubConyainer .container .title .infoTexts .dateInfo .contact .wtsCont .icon {
    width: 20px;
    height: 20px;
    min-width: 20px;
  }

  .unsubConyainer .container .title .infoTexts .dateInfo .contact .emailCont .icon {
    width: 20px;
    height: 20px;
    min-width: 20px;
  }

  .unsubConyainer .container .title .infoTexts .dateInfo .contact div p {
    font-size: 2vw;
  }

  /* ------------------------------ */

  .unsubConyainer .container .formCont {
    width: 100%;
    min-width: 350px;
    height: auto;

    margin: 30px auto;
  }

  /* ----------------------------- */

  .unsubConyainer footer a {
    padding: 7px 18px;
    font-size: 14px;
  }

  .unsubConyainer .logoVigilado {
    width: calc(30vw * 0.096);
    height: 30vw;
  }
}

/* ===================================== */

@media screen and (max-width: 580px) {

  .unsubConyainer .container {
    margin: 0px auto 50px auto;
  }

  .unsubConyainer .container .title .logoFDN {
    width: 40vw;
    height: calc(40vw / 3.1528);
    margin: 10% auto 0% 2%;
  }

  .unsubConyainer .container .title .infoTexts {
    margin: 5% auto auto auto;
    width: 97%;
  }

  .unsubConyainer .container .title .infoTexts h1 {
    margin: 20px 0 auto auto;
    font-size: 4.5vw;
    line-height: 4.5vw;
  }

  .unsubConyainer .container .title .infoTexts .subtitle .lines {
    height: 20vw;
  }

  .unsubConyainer .container .title .infoTexts .subtitle h2 {
    font-size: 4vw;
    line-height: 4.5vw;
    width: 80%;
    margin: auto auto auto 2%;
  }

  .unsubConyainer .container .title .infoTexts .dateInfo {
    margin: 5% auto;
  }

  .unsubConyainer .container .title .infoTexts .dateInfo div {
    width: 25%;
  }

  /* ============================ */

  .unsubConyainer .container .title .infoTexts .dateInfo .date h4 {
    font-size: 2.5vw;
    line-height: 2.5vw;
  }

  .unsubConyainer .container .title .infoTexts .dateInfo .date p {
    font-size: 2.5vw;
    line-height: 2.5vw;
  }

  /* --------------------- */

  .unsubConyainer .container .title .infoTexts .dateInfo .place {
    width: 30%;
  }

  .unsubConyainer .container .title .infoTexts .dateInfo .place h4 {
    width: 95%;
    margin: 5px auto;
    font-size: 2.5vw;
    line-height: 2.5vw;
  }

  .unsubConyainer .container .title .infoTexts .dateInfo .place p {
    width: 95%;
    font-size: 2.5vw;
    line-height: 2.5vw;
  }

  /* ------------------------ */

  .unsubConyainer .container .title .infoTexts .dateInfo .contact {
    width: 45%;
  }

  .unsubConyainer .container .title .infoTexts .dateInfo .contact h4 {
    font-size: 2.5vw;
    line-height: 2.5vw;
  }

  .unsubConyainer .container .title .infoTexts .dateInfo .contact .wtsCont .icon {
    width: 20px;
    height: 20px;
    min-width: 20px;
  }

  .unsubConyainer .container .title .infoTexts .dateInfo .contact .emailCont .icon {
    width: 20px;
    height: 20px;
    min-width: 20px;
  }

  .unsubConyainer .container .title .infoTexts .dateInfo .contact div p {
    font-size: 2vw;
  }

  /* ------------------------------ */

  .unsubConyainer .container .formCont {
    width: 100%;
    min-width: 250px;
    height: auto;

    margin: 30px auto;
  }

  /* ----------------------------- */

  .unsubConyainer footer a {
    padding: 7px 18px;
    font-size: 14px;
  }

  .unsubConyainer .logoVigilado {
    width: calc(40vw * 0.096);
    height: 40vw;
  }

}

