@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: Grafik_Light;
  src: url('./fonts/GraphikLight.woff');
}

@font-face {
  font-family: Grafik_Light_Italic;
  src: url('./fonts/GraphikLightItalic.woff');
  font-style: italic;
}

@font-face {
  font-family: Grafik_Medium;
  src: url('./fonts/GraphikMedium.woff');
}

@font-face {
  font-family: Grafik_Regular;
  src: url('./fonts/GraphikRegular.woff');
}

@font-face {
  font-family: Grafik_Regular_Italic;
  src: url('./fonts/GraphikRegularItalic.woff');
  font-style: italic;
}

@font-face {
  font-family: Grafik_Semibold;
  src: url('./fonts/GraphikSemibold.woff');
}

@font-face {
  font-family: Grafik_Semibold_Italic;
  src: url('./fonts/GraphikSemiboldItalic.woff');
  font-style: italic;
}

@font-face {
  font-family: Grafik_Bold;
  src: url('./fonts/GraphikBold.woff');
}

@font-face {
  font-family: Grafik_Bold_Italic;
  src: url('./fonts/GraphikBoldItalic.woff');
  font-style: italic;
}

:root {
  --color-main: #781123;
  --color-secundary: #ca0c2c;
  --color-error: #FF4D6B;
  --color-success: #A4C54E;
  --color-darkBlue: #002342;
  --color-darkUIMain: #111;
  --color-white: #FFF;
  --color-gray: #e5e5e5;
}

html {
  background-color: #000;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}


body #root {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;

  z-index: 20000;
}
*::-webkit-scrollbar-track {
  background: #FFF0;
}
*::-webkit-scrollbar-thumb {
  background: var(--color-main);
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb:hover {
  background: var(--color-darkBlue);
}

.swal2-popup {
  border-radius: 20px !important;
}

.swal2-title {
  padding: 0px !important;
}

.swal2-title br {
  font-size: 0 !important;
}

.swal2-image {
  margin: 3vw auto 1vw auto !important;
}

.closePopUp,
.closeModalSwal {
  position: absolute;
  top: -20px;
  right: 0%;
  color: #FFF !important;
  background-color: var(--color-main) !important;
}


/* ---------------------------- */

.miniloader {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;

  border: 3px solid transparent;
  border-top: 3px solid var(--color-white);
  border-left: 3px solid var(--color-white);
  border-bottom: 3px solid var(--color-white);
  border-radius: 50%;

  margin: 5px;

  animation-name: girar;
  animation-duration: .9s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.preloader {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;

  border: 5px solid transparent;
  border-top: 5px solid var(--color-main);
  border-left: 5px solid var(--color-main);
  border-bottom: 5px solid var(--color-main);
  border-radius: 50%;

  margin: 10px auto;

  animation-name: girar;
  animation-duration: .9s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.whiteL {
  border: 3px solid transparent;
  border-top: 3px solid #FFF;
  border-left: 3px solid #FFF;
  border-bottom: 3px solid #FFF;
}

@keyframes girar {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ----------------------------------------- */

/* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
